// React
import { ReactElement } from 'react';
// Types
import { Slice } from '@utmb/types/slice-handler';
import { SlicePictureOnlyView } from '@utmb/types/index';
// Design System
import { Image } from '@utmb/design-system/image/Image';
// Styles
import styles from './picture-only.module.scss';

export const PictureOnly = (props: Slice<SlicePictureOnlyView>): ReactElement => {
    const { id, picture } = props;

    if (picture === null) return <></>;

    return (
        <div id={id} className={`${styles.container} container`}>
            <Image image={picture} layout="responsive" width={picture.width} height={picture.height} />
        </div>
    );
};
